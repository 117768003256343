import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

document.addEventListener('DOMContentLoaded', function() {
    const twigDataEl = $('.js-base');
    const environment = twigDataEl.data('appEnvironment');
    const user = twigDataEl.data('appUser');
    const customUser = twigDataEl.data('customUser');
    const url_user_show = twigDataEl.data('urlUser');
    const url_contract_show = twigDataEl.data('urlContract');
    let certification = customUser.crispCertification;
    let role ='N/C';

    switch (user.role) {
    case 1:
        role = 'APPRENANT';
        break;
    case 2:
        role = 'MANAGER APPRENANT';
        break;
    case 3:
        role = 'PILOTE APPRENANT';
        break;
    case -1:
        role = 'MANAGER';
        break;
    case -2:
        role = 'PILOTE';
        break;
    case 0:
        role = 'TESTEUR';
        break;
    case 5:
        role = 'ADMIN';
        break;
    case 4:
        role = 'ETUDIANT';
        break;
    }

    $.date = function(dateObject) {
        var d = new Date(dateObject);
        var day = d.getDate();
        var month = d.getMonth() + 1;
        var year = d.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var date = day + '/' + month + '/' + year;

        return date;
    };

    if (environment && environment !== 'dev') {
        // Sentry
        Sentry.init({
            dsn: 'https://8aaea151ee13488ea8a1151d1881c7bb@o156942.ingest.sentry.io/1215288',
            release: 'le-parcours@' + process.env.npm_package_version,
            integrations: [new Integrations.BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
        });

        // Global site tag (gtag.js) - Google Analytics
        ///////////////////////////////////////////////
        window.dataLayer = window.dataLayer || [];

        // eslint-disable-next-line no-inner-declarations
        function gtag () {window.dataLayer.push(arguments);}

        gtag('js', new Date());
        gtag('config', 'UA-109664696-1');

        // new js code for GTM
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-5625PCM');

        // Crisp
        ////////
        window.$crisp=[];
        window.$crisp.push(['safe', true]);
        window.CRISP_WEBSITE_ID='4c02ab15-ee7a-46cf-9a58-8bbafc540e24';
        (function(){
            var d=document;
            var s=d.createElement('script');
            s.src='https://client.crisp.chat/l.js';
            s.async=1;
            d.getElementsByTagName('head')[0].appendChild(s);
        })();

        $('#crisp-button').click(function () {
            window.$crisp.push(['do', 'chat:open']);
        });

        if (user) {
            window.$crisp.push(['set', 'user:email', user.email]);
            window.$crisp.push(['set', 'user:nickname', `${user.name} ${user.surname}`]);
            window.$crisp.push(['set', 'session:segments', role]);
            window.$crisp.push(['set', 'session:data', [
                [
                    ['role', role],
                    ['fiche', url_user_show],
                    ['ID', user.id],
                    ['type', user.contract.type.name],
                    ['expiration', $.date(user.expiration)],
                    ['session', url_contract_show],
                    ['certification', certification]
                ]
            ]]);
        }

        // Heap Analytics
        /////////////////
        window.heap = window.heap || [], heap.load = function (e, t) {
            window.heap.appid = e, window.heap.config = t = t || {};
            var r = t.forceSSL || 'https:' === document.location.protocol, a = document.createElement('script');
            a.type = 'text/javascript', a.async = !0, a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js';
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(a, n);
            for (var o = function (e) {return function () {heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));};}, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], c = 0; c < p.length; c++) heap[p[c]] = o(p[c]);
        };
        heap.load('2286067047');

    } else {

        // Heap Analytics
        /////////////////
        window.heap = window.heap || [], heap.load = function (e, t) {
            window.heap.appid = e, window.heap.config = t = t || {};
            var r = t.forceSSL || 'https:' === document.location.protocol, a = document.createElement('script');
            a.type = 'text/javascript', a.async = !0, a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js';
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(a, n);
            for (var o = function (e) {return function () {heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));};}, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], c = 0; c < p.length; c++) heap[p[c]] = o(p[c]);
        };
        heap.load('2433124482');

    }

    if (user && !(Object.keys(user).length === 0 && user.constructor === Object)) {

        // Heap Analytics datas
        heap.identify(user.email);
        heap.addUserProperties({
            'Name': `${user.name} ${user.surname}`,
            'Session': user.contract.reference,
            'Company': user.company.name,
            'Created Date': $.date(user.created),
            'Expiration Date': $.date(user.expiration),
            'Role': role
        });
    }
});
